
import React from 'react';
import {injectIntl} from 'gatsby-plugin-intl';

import Layout from 'components/layout/Layout';
import Seo from 'components/common/Seo';
import {getUserRole} from 'services/auth';

const NotFoundPage = ({intl}) => {
  getUserRole();

  return (
      <Layout>
          <Seo title={intl.formatMessage({ id: "404.seo" })}></Seo>

          <h1>{intl.formatMessage({ id: "404.title" })}</h1>

          <p>{intl.formatMessage({ id: "404.message" })}</p>

      </Layout>
  )
}

export default injectIntl(NotFoundPage)
